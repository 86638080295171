<template>
  <div @click="() => {seeBtns = false}">
    <div class="banner">
    <img :src="collect.banner.image"/>
    </div>
    <div class="series_box">
      <div class="seriesImg_box">
        <img  class="series_img" :src="collect.icon"/>
      </div>
      <div class="series_name">
        <h5>{{collect.name}}</h5>
        <ul class="btns" v-if="!isMobile">
          <li v-for="(item,index) in collect.social" :key="index">
            <a :href="item.url" target="_blank">
              <img :src="item.icon">
            </a>
          </li>
          <li>
            <a @click="doCopy"></a>
          </li>
        </ul>
        <div class="more_btn" @click.stop="setSeeBtns" v-else></div>
        <ul class="btns" v-if="isMobile && seeBtns">
          <li v-for="(item,index) in collect.social" :key="index">
            <a :href="item.url" target="_blank">
              <img :src="item.icon">
            </a>
          </li>
          <li>
            <a @click="doCopy"></a>
          </li>
        </ul>
      </div>
      <div class="series_explain">
        <p :class="{all : seeMore}">
          {{collect.description}}
        </p>
        <template v-if="collect.description&&collect.description.length>160">
          <div class="more" @click="setSeeMore" v-if="seeMore">
            See More
            <img src="@/assets/images/search/more_down.png" alt="">
          </div>
          <div class="more" @click="setSeeMore" v-else>
            See less
            <img src="@/assets/images/search/more_down.png" alt="" class="less">
          </div>
        </template>
      </div>
    </div>
    <searchList :collection = false></searchList>
  </div>
</template>

<script>
import searchList from '../components/search-list.vue'
import { mapState } from "vuex";
import { collectionDetail } from '@/api/index.js'
export default {
  name:'searchDetail',
  components:{
    searchList
  },
  data() {
    return {
      seeMore:true,
      seeBtns:false,
      collect:{
        banner:{},
        social:{}
      }
    }
  },
  mounted() {
    this.getCollectionDetail()
  },
  methods: {
    getCollectionDetail(){
      collectionDetail({identify:this.$route.params.identify,sort_type:''})
          .then( (data) => {
            this.collect = data.data.collect;
          })
          .catch(err => {console.log(err);})
    },
    setSeeMore(){
      this.seeMore = this.seeMore ? false : true
    },
    setSeeBtns(){
      this.seeBtns = this.seeBtns ? false : true
    },
    doCopy() {
      const message = window.location.href;
      this.$copyText(message).then(
          () => {
            this.$notification["success"]({
              message: "Link copied!",
            });
          },
          () => {
            this.$notification["error"]({
              message: "Link copy failed",
            });
          }
      );
    }
  },
  computed:{
      ...mapState({
     isMobile:(state) => state.isMobile
  })
  }
}
</script>

<style lang="scss" scoprd>
  .banner{
    width: 100%;
    max-width: 1920px;
    min-width: 1200px;
    height: 310px;
    position: relative;
    margin: 0 auto;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .series_box{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .seriesImg_box{
      position: absolute;
      top: -135px;
      left: 0;
      width: 158px;
      height: 163px;
      background: url("~@/assets/images/search/series_bg.svg") no-repeat;
      -webkit-mask-image: url("~@/assets/images/search/series_bg.svg");
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
    }
    .series_img{
      width: 100%;
      height: 100%;
      -webkit-mask-image: url("~@/assets/images/search/series_bg.svg");
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: 94% ;
    }
    .series_name{
      padding-top: 67px;
      display: flex;
      justify-content: space-between;
      h5{
        font-size: 30px;
        font-family: "SFPixelate-Bold";
        color: #FFFFFF;
        line-height: 32px;
      }
      .btns{
        display: flex;
        align-items: center;
          li {
            a{
              display: inline-block;
              width: 40px;
              height: 40px;
              background: url("~@/assets/images/search/web.svg") no-repeat;
              margin-left: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              img{
                width: 24px;
                height: 24px;
              }
              &:hover{
                background: url("~@/assets/images/search/web-hover.svg") no-repeat;
              }
            }
            &:first-child a{
              margin: 0;
            }
            &:last-child a{
              background: url("~@/assets/images/search/share.svg") no-repeat;
              &:hover{
                background: url("~@/assets/images/search/share-hover.svg") no-repeat;
              }
            }
        }
      }
    }
    .series_explain{
      width: 100%;
      max-width: 800px;
      font-family: "SFPixelate-Bold";
      margin-bottom: 24px;
      margin-top: 24px;
      p{
        font-size: 16px;
        color: #FFFFFF;
        line-height: 17px;
      }
      .all{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
      .more{
        font-size: 14px;
        color: #537171;
        line-height: 16px;
        margin-top: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
          margin-left: 8px;
        }
        .less{
          transform: rotate(180deg);
        }
      }
    }
  }
  @media screen and(max-width: 750px) {
    .banner{
      min-width: auto;
      width: 100%;
      height: 1.88rem;
    }
    .series_box{
      width: 100%;
      padding: 0 .6rem 0 .4rem;
      .seriesImg_box{
        top: -1.18rem;
        left: .4rem;
        width: 1.76rem;
        height: 1.76rem;
        background: url("~@/assets/images/search/seriesBg_mo.svg") no-repeat;
        -webkit-mask-size: 100% ;
        background-size: contain;
        -webkit-mask-image: url("~@/assets/images/search/seriesBg_mo.svg");
        .series_img{
        -webkit-mask-image: url("~@/assets/images/search/seriesBg_mo.svg");
        -webkit-mask-size: 94% ;
        }
      }
      .series_name{
        padding-top: 1.18rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        h5{
          font-size: .4rem;
          line-height: .44rem;
        }
        .more_btn{
          width: .4rem;
          height: .4rem;
          background: url("~@/assets/images/search/more_btns.svg") no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .btns{
          position: absolute;
          right: 0;
          bottom: -1rem;
          background-color: #0C2D33;
          li{
            a{
              width: .8rem;
              height: .8rem;
              margin-left: .12rem;
              background-size: contain !important;
              img{
                width: .48rem;
                height: .48rem;
              }
            }
          }
        }
      }
      .series_explain{
          max-width: 5.3rem;
          margin-bottom: .24rem;
          margin-top: .4rem;
          p{
            font-size: .28rem;
            line-height: .32rem;
          }
          .all{
               -webkit-line-clamp: 6;
            }
          .more{
            font-size: .24rem;
            line-height: .28rem;
            margin-top: .2rem;
            img{
              margin-left: .16rem;
            }
          }
        }
    }
  }
</style>
